import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomePageState, MeasurementPageState, PageKey, ResultPageState, ResultTabKey } from "./pageState";
import * as localStorage from "src/helper/storage/localStorage";
import ConsentDialog from "src/pages/splus3/dialogs/ConsentDialog";
import { AppThunk, RootState } from "src/store";
import { VARIANT } from "../../config";
import { selectPrepareResult } from "../measurement/utils";
import { useSelector } from "react-redux";

interface PageManagerState {
    currentPage: PageKey;
    homeState: HomePageState;
    measurementState: MeasurementPageState;
    resultState: ResultPageState;
}

const initialState: PageManagerState = {
    currentPage: PageKey.Home,
    homeState: {},
    measurementState: {},
    resultState: {
        currentTab: ResultTabKey.CurrentMesasurement,
        showSurveyModal: false,
        lastSurveyDate: null
    },
}

export const initPageManager = (): AppThunk => (dispatch) => {
    const legacyOfferedSurvey = localStorage.getOfferedSurvey();
    const lastSurveyDate = localStorage.getLastSurveyDate();

    if ((legacyOfferedSurvey === true) && (lastSurveyDate === null)) {
        const currentDate = new Date();
        localStorage.setLastSurveyDate(currentDate)
        dispatch(setLastSurveyDate(currentDate))
    } else {
        dispatch(setLastSurveyDate(lastSurveyDate))
    }
};


export const pageManagerSlice = createSlice({
    name: 'pageManager',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<PageKey>) => {
            state.currentPage = action.payload;
            return state
        },
        updateResultPageTab: (state, action: PayloadAction<ResultTabKey>) => {
            state.resultState.currentTab = action.payload;
            return state
        },
        setShowSurvey: (state, action: PayloadAction<boolean>) => {
            state.resultState.showSurveyModal = action.payload
            return state
        },
        setLastSurveyDate: (state, action: PayloadAction<Date | null>) => {
            state.resultState.lastSurveyDate = action.payload
            return state
        }
    }
});

export const {
    updatePage,
    updateResultPageTab,
    setShowSurvey,
    setLastSurveyDate
} = pageManagerSlice.actions;

export default pageManagerSlice;